import React from "react";
import Work from "./Work";
import MyArt from "./MyArt";

const Main = () => {
  return (
    <div className="">
      <Work />
      <MyArt />
    </div>
  );
};

export default Main;
