const data = [
  {
    name: "134 Agency",
    type: "Website",
    image: "134agency.png",
    link: "134-agency",
    page: {
      title: "134 Agency",
      type: "Website",
      link: "https://www.134agency.com/",
      pageData: [
        { type: "image", image: "134-1.png" },
        {
          type: "text",
          header: "Project Overview",
          text: "134 Agency’s website showcases its specialized digital marketing services, including PPC, branding, and web development, aimed at accelerating client growth through strategic, data-driven approaches. The agency focuses on personalized solutions and measurable outcomes to help businesses scale efficiently and sustainably.",
        },
        { type: "image", image: "134-2.png" },
        {
          type: "goals",
          goals: [
            {
              num: "1st Goal",
              text: "Optimize the website design to boost engagement and maximize conversions.",
            },
            {
              num: "2nd Goal",
              text: "Create a clean, minimalist design that enhances user focus and simplifies navigation for a seamless experience.",
            },
            {
              num: "3rd Goal",
              text: "Emphasize a user-centered, seamless design approach that eliminates distractions and draws attention to key actions.",
            },
          ],
        },
        { type: "image", image: "134-3.png" },
        {
          type: "text",
          header: "How did i achive the goals?",
          text: "I achieved the goals by designing a conversion-focused website that emphasizes a clean, minimalist aesthetic. This approach draws attention to key actions and guides users seamlessly through the site. We prioritized a user-centered experience by simplifying navigation, using clear calls to action, and eliminating distractions. Finally, we incorporated data-driven insights to refine the design continuously, maximizing performance and engagement.",
          button: true,
        },
      ],
    },
  },
  {
    name: "MontobeneInvest",
    type: "Website",
    image: "Montobene.png",
    link: "montobeneinvest",
    page: {
      title: "Montobene Invest",
      type: "Website",
      link: "https://www.montobene-invest.rs/",
      pageData: [
        { type: "image", image: "Montobene1.png" },
        {
          type: "text",
          header: "Project Overview",
          text: "Montobene Invest is a reputable Serbian real estate company with over 25 years of success in the region. Specializing in modern, high-quality residential projects, they focus on delivering luxury living spaces with advanced smart home systems for enhanced comfort. Their portfolio includes premium apartments and penthouses in Niš, emphasizing prime locations and superior materials. Montobene Invest has built a strong brand known for reliability and long-term customer trust.",
        },
        { type: "image", image: "Montobene2.png" },
        {
          type: "goals",
          goals: [
            {
              num: "1st Goal",
              text: "Develop a new, premium website that reflects the company's high standards and innovative approach to smart living.",
            },
            {
              num: "2nd Goal",
              text: "Present clear and relevant information to potential buyers in a user-friendly format.",
            },
            {
              num: "3rd Goal",
              text: "Increase the company’s digital presence and attract a larger number of apartment buyers through targeted digital marketing efforts.",
            },
          ],
        },
        { type: "image", image: "Montobene3.png" },
        {
          type: "text",
          header: "How did I achive the goals?",
          text: "To match Montobene Invest’s high standards, I designed a sleek, minimalist website with a focus on premium aesthetics and intuitive functionality. The design incorporates spacious layouts, elegant typography, and a neutral color palette to evoke luxury. I integrated clear property showcases with full-width images and interactive elements, enhancing the user experience. The navigation was streamlined, ensuring potential buyers could easily explore available projects. This modern, visually appealing design aligns with Montobene's innovative approach to smart living and effectively communicates their brand values.",
          button: true,
        },
      ],
    },
  },
  {
    name: "Balkan Fun",
    type: "Website",
    image: "Balkan-fun.png",
    link: "balkan-fun",
    page: {
      title: "Balkan Fun",
      type: "Website",
      link: "",
      pageData: [
        { type: "image", image: "Balkanfun1.png" },
        {
          type: "text",
          header: "Project Overview",
          text: "Balkan Fun Travel offers customized travel experiences, focusing on adventure, culture, and unique destinations. Their website makes it easy for customers to explore various travel packages, ensuring seamless planning for memorable vacations. With a user-friendly design, the site reflects the agency's commitment to delivering tailored travel solutions.",
        },
        { type: "image", image: "Balkanfun2.png" },
        {
          type: "goals",
          goals: [
            {
              num: "1st Goal",
              text: "Redesign the website to offer a sleek, modern look with a clean, user-friendly layout that enhances clarity and navigation.",
            },
            {
              num: "2nd Goal",
              text: "Optimize the website design to boost engagement and maximize conversions.",
            },
            {
              num: "3rd Goal",
              text: "Simplify the design with larger text, clear navigation, and high-contrast colors for better readability and ease of use for older users.",
            },
          ],
        },
        { type: "image", image: "Balkanfun3.png" },
        {
          type: "text",
          header: "How did I achive the goals?",
          text: "I achieved the goals by simplifying the website design with larger text, clearer navigation, and high-contrast colors for better readability. We reduced clutter and focused on intuitive, easy-to-use features that cater to an older audience. Key information is now more accessible, with straightforward language and minimal steps to guide users seamlessly through their journey. This approach enhanced both clarity and user experience.",
          button: false,
        },
      ],
    },
  },
  {
    name: "Lazar G Portfolio",
    type: "Website",
    image: "Lazar-portfolio.png",
    link: "lazar-g-portfolio",
    page: {
      title: "Developer Portfolio",
      type: "Website",
      link: "",
      pageData: [
        { type: "image", image: "Lazar1.png" },
        {
          type: "text",
          header: "Project Overview",
          text: "Lazar Golubovic is young and perspective full stack developer with over 3 years of experience. The idea behind this design was to incorporate Viking elements and to be minimalistic at the same time.",
        },
        { type: "image", image: "Lazar2.png" },
        {
          type: "goals",
          goals: [
            {
              num: "1st Goal",
              text: "Incorporate Viking elements into the design without it being too overwhelming.",
            },
            {
              num: "2nd Goal",
              text: "Make the design minimalistic with minimal information and elements displayed.",
            },
            {
              num: "3rd Goal",
              text: "Add minimalistic interactions and animations to the design for elegance and smoothness.",
            },
          ],
        },
        { type: "image", image: "Lazar3.png" },
        {
          type: "text",
          header: "How did I achive the goals?",
          text: "To achieve these goals, I began by drawing inspiration from the Viking era, ultimately choosing Viking runes as the core design element. From there, I explored minimalist aesthetics, finding a way to blend the boldness of Viking symbols with a clean, streamlined design. Adding interactive elements brought the design to life, giving it that polished, smooth feel that stands out. These interactions not only enhance the overall aesthetic but also provide a seamless experience intended to captivate hiring managers and make a lasting impression on developers.",
          button: false,
        },
      ],
    },
  },
  {
    name: "Ono Moje",
    type: "Package",
    image: "OnoMoje.png",
    link: "https://www.instagram.com/p/DCCrqd8OT3k/?img_index=1",
  },
  {
    name: "Astro",
    type: "Website",
    image: "Astro.png",
    link: "https://www.instagram.com/p/DB9pf6VO7om/?img_index=1",
  },
  {
    name: "EqolQ",
    type: "Website",
    image: "EcolQ.png",
    link: "https://www.instagram.com/p/DCAmfg5iamd/?img_index=1",
  },

  {
    name: "Adventure",
    type: "Website",
    image: "Adventure.png",
    link: "https://www.instagram.com/p/DBzwcE_CtA4/?img_index=1",
  },
  {
    name: "Ink Alchemy",
    type: "Website",
    image: "InkAlchemy.png",
    link: "https://www.instagram.com/p/DB2R4f9tmVb/?img_index=1",
  },
  {
    name: "Adopt Good",
    type: "Website",
    image: "Adopt.png",
    link: "https://www.instagram.com/p/DB4JyHzsK_p/?img_index=1",
  },
  {
    name: "Musik",
    type: "Website",
    image: "AfterHours.png",
    link: "https://www.instagram.com/p/DB7RvG-NUFI/?img_index=1",
  },

  {
    name: "Flow of Creativity",
    type: "Poster",
    image: "Flow.png",
    link: "https://www.instagram.com/p/C92HZy5sJPa/?img_index=1",
  },
];

export default data;
