import React, { useState } from "react";
import { Hamburger, Logo } from "../Images/Icons";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-router-dom";

const Navigation = ({ project }) => {
  const [isOpenNav, setIsOpenNav] = useState(false);

  return (
    <nav className="flex justify-between items-center text-secondary font-light z-50 fixed w-[calc(100%-2rem)] md:w-[calc(100%-4rem)] xl:w-[calc(100%-8rem)] top-8">
      {project ? (
        <Link to={"/"}>
          <Logo />
        </Link>
      ) : (
        <AnchorLink href="#home" offset={200} className="relative z-50">
          <Logo />
        </AnchorLink>
      )}
      {project ? (
        ""
      ) : (
        <ul
          className={`flex flex-col sm:flex-row h-screen w-screen sm:h-max sm:w-max gap-4 sm:gap-16 text-2xl font-semibold sm:text-base sm:font-normal items-center justify-center px-16 py-32 sm:py-4 sm:backdrop-blur-sm sm:rounded-lg bg-primary sm:bg-white/10 fixed bottom-0 left-0 sm:bottom-8 sm:left-1/2 sm:-translate-x-1/2 z-40 transition-[transform] duration-500 sm:duration-0 ${
            isOpenNav ? "" : "-translate-y-full sm:translate-y-0"
          }`}
        >
          <AnchorLink
            href="#home"
            offset={200}
            className="sm:hidden"
            onClick={() => setIsOpenNav(false)}
          >
            Home
          </AnchorLink>
          <AnchorLink
            href="#work"
            offset={80}
            className="hover:font-medium transition-[font-weight] duration-300"
            onClick={() => setIsOpenNav(false)}
          >
            Work
          </AnchorLink>
          <AnchorLink
            href="#art"
            offset={-70}
            className="hover:font-medium transition-[font-weight] duration-300"
            onClick={() => setIsOpenNav(false)}
          >
            Follow me
          </AnchorLink>
          <AnchorLink
            href="#contact"
            className="hover:font-medium transition-[font-weight] duration-300"
            onClick={() => setIsOpenNav(false)}
          >
            Contact
          </AnchorLink>
        </ul>
      )}

      <div
        className={`gap-2 items-center  ${project ? "flex" : "hidden sm:flex"}`}
      >
        <div className="size-3 rounded-full bg-[#3D9E25] animation-pulse relative"></div>
        <p>Available for work</p>
      </div>
      {project ? (
        ""
      ) : (
        <div
          className="sm:hidden relative z-50"
          onClick={() => setIsOpenNav(!isOpenNav)}
        >
          <Hamburger />
        </div>
      )}
    </nav>
  );
};

export default Navigation;
