import React from "react";
import { Behance, Instagram, Linkedin } from "../Images/Icons";

const Footer = () => {
  return (
    <div id="contact">
      <div className="bg-[#191718] rounded-2xl flex flex-col items-center py-36 relative overflow-hidden z-10 px-4">
        <h2 className="text-secondary font-bold text-4xl md:text-6xl xl:text-8xl">
          Let's Work!
        </h2>
        <p className="text-base md:text-xl text-light mt-4 text-center">
          Contact me via email for collaboration
        </p>
        <a
          href="mailto:markomarkovicdesigns@gmail.com"
          target="_blank"
          rel="noreferrer"
          className="md:text-xl text-secondary px-4 text-sm sm:text-base sm:px-12 py-5 sm:py-6 rounded-2xl bg-white/10 mt-16"
        >
          markomarkovicdesigns@gmail.com
        </a>
        <div className="absolute left-full -translate-x-1/2 top-1/2 -translate-y-1/2 -z-10 h-full aspect-video hidden sm:block">
          <img
            src={require("../Images/Footer.png")}
            alt="Statue"
            className="object-cover"
          />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row gap-4 justify-between text-secondary mt-4">
        <p className="bg-[#191718] rounded-2xl px-8 py-4 font-light text-sm sm:text-base w-max">
          © 2024 MarkoDesignx | All Rights Reserved
        </p>
        <div className="flex gap-4 items-center">
          <a
            className="icon"
            href="https://www.instagram.com/marko.designx"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
          <a
            className="icon"
            href="https://www.linkedin.com/in/markomarkovicdesigns"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
          <a
            className="icon"
            href="https://www.behance.net/markomarkovicdesigns"
            target="_blank"
            rel="noreferrer"
          >
            <Behance />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
