import React from "react";
import { MyArtText } from "../../Images/Icons";

const MyArt = () => {
  return (
    <div
      className="relative py-60 sm:py-96 flex flex-col items-center z-10"
      id="art"
    >
      <MyArtText />
      <h2 className="font-black text-3xl md:text-5xl xl:text-7xl text-secondary mt-8">
        Follow My Work
      </h2>
      <p className="text-base md:text-xl text-light mt-4">
        Don’t miss out on my latest projects
      </p>
      <a
        href="https://www.instagram.com/marko.designx"
        target="_blank"
        rel="noreferrer"
        className="text-xl text-secondary px-12 py-6 rounded-2xl bg-white/10 mt-16 z-10 relative"
      >
        @marko.designx
      </a>
      <div className="absolute right-0 translate-x-1/2 sm:-translate-y-1/4 bottom-0 -rotate-45 max-w-[50vw] -z-10">
        <img src={require("../../Images/Rock-right.png")} alt="rock" />
      </div>
      <div className="absolute left-0 -translate-x-2/3 sm:-translate-y-1/3 top-0 sm:top-1/3 rotate-45 max-w-[50vw] -z-10">
        <img src={require("../../Images/Rock-left.png")} alt="rock" />
      </div>
    </div>
  );
};

export default MyArt;
