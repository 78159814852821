import React, { useLayoutEffect } from "react";
import Navigation from "../Navigation";

const ProjectPage = ({ data }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const ExternalLink = () => {
    return (
      <a
        href={data.link}
        target="_blank"
        rel="noreferrer"
        className="font-light px-10 sm:px-12 py-4 sm:py-5 rounded-2xl bg-white/10 w-max shrink-0"
      >
        Visit Website
      </a>
    );
  };
  return (
    <div className="text-secondary pt-20 pb-32" id="home">
      <Navigation project={true} />
      <div>
        <div className="flex lg:justify-between flex-col lg:flex-row gap-y-12 lg:items-center">
          <div className="flex flex-col gap-4">
            <h2 className="text-4xl sm:text-6xl lg:text-8xl font-bold">
              {data.title}
            </h2>
            <p className="text-xl text-light">{data.type}</p>
          </div>
          {data.link.length >= 1 ? <ExternalLink /> : ""}
        </div>
        <div>
          {data.pageData.map((el) => {
            return el.type === "image" ? (
              <img
                src={require(`../../Images/ProjectPageImages/${el.image}`)}
                alt="images"
                className="pt-8 md:pt-12 xl:pt-16 pb-20 md:pb-28 xl:pb-32 w-full rounded-2xl"
              ></img>
            ) : el.type === "text" ? (
              <div className="flex lg:justify-between flex-col lg:flex-row gap-y-12 gap-x-32 items-start">
                <div>
                  <h3 className="text-xl mb-6">{el.header}</h3>
                  <p className="text-light sm:max-w-[70ch] md:max-w-[105ch]">
                    {el.text}
                  </p>
                </div>
                {el.button ? <ExternalLink /> : ""}
              </div>
            ) : el.type === "goals" ? (
              <div className="grid lg:grid-cols-3 gap-4 lg:gap-8">
                {el.goals.map((goal) => {
                  return (
                    <div className="px-8 md:px-12 py-14 md:py-20 rounded-2xl bg-[#191718]">
                      <h4 className="text-xl mb-4">{goal.num}</h4>
                      <p className="text-light">{goal.text}</p>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ProjectPage;
