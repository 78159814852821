import React, { useLayoutEffect } from "react";
import Navigation from "./Navigation";
import { Behance, Instagram, Linkedin } from "../Images/Icons";

const Header = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  // Smanji sa 4 na 2 rem zbog bottom padding
  return (
    <header className="lg:h-[calc(100vh-2rem)] h-screens" id="home">
      <Navigation />
      <div className="relative h-[calc(100%-1rem)]">
        <div className="flex items-center gap-32s w-screen absolute top-1/2 -translate-y-[calc(50%+2rem)] uppercase">
          <h1 className="font-black text-[200px] md:text-[300px] text-gradient animation-slider w-max min-w-[66.67vw] grid place-items-center shrink-0 grow-0">
            Design
          </h1>
          <p className="font-black text-[200px] md:text-[300px] text-gradient animation-slider w-max min-w-[66.67vw] grid place-items-center shrink-0 grow-0">
            Design
          </p>
          <p className="font-black text-[200px] md:text-[300px] text-gradient animation-slider w-max min-w-[66.67vw] grid place-items-center shrink-0 grow-0">
            Design
          </p>
        </div>

        <img
          src={require("../Images/Header-image.png")}
          alt="Sculpture"
          className="lg:absolute relative block -bottom-14 left-1/2 mt-20 lg:mt-0 -translate-x-1/2 z-10 sm:max-w-[70vw]"
        />
        {/* Left Side Icons */}
        <div className="absolute sm:bottom-16 -bottom-28 left-1/2 -translate-x-1/2 sm:left-0 sm:translate-x-0 flex sm:flex-col gap-3">
          <a
            className="icon"
            href="https://www.instagram.com/marko.designx"
            target="_blank"
            rel="noreferrer"
          >
            <Instagram />
          </a>
          <a
            className="icon"
            href="https://www.linkedin.com/in/markomarkovicdesigns"
            target="_blank"
            rel="noreferrer"
          >
            <Linkedin />
          </a>
          <a
            className="icon"
            href="https://www.behance.net/markomarkovicdesigns"
            target="_blank"
            rel="noreferrer"
          >
            <Behance />
          </a>
        </div>
        {/* Wonderful Text */}
        <div className="flex flex-col justify-center gap-6 p-4 bg-secondary text-primary font-semibold w-max fixed top-1/2 -translate-y-1/2 right-0 z-20">
          <div className="text-2xl">W</div>
          <p className="vertical-text leading-none grid place-items-center text-sm">
            Wonderful
          </p>
        </div>
      </div>
    </header>
  );
};

export default Header;
