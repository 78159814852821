import { Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Main from "./components/Main/Main";
import data from "./data";
import ProjectPage from "./components/ProjectPage/ProjectPage";

function App() {
  const HomePage = () => {
    return (
      <>
        <Header />
        <Main />
      </>
    );
  };
  return (
    <div className="bg-primary px-4 md:px-8 xl:px-16 py-8 overflow-x-hidden">
      <Routes>
        <Route path={"/"} element={<HomePage />} />
        {data.map((el) => (
          <Route
            key={el.link}
            path={el.link}
            element={<ProjectPage data={el.page} />}
          />
        ))}
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
