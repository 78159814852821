import React from "react";
import data from "../../data";
import { Link } from "react-router-dom";

const Work = () => {
  const Project = ({ data }) => {
    return (
      <>
        <div className="rounded-lg overflow-hidden">
          <img
            src={require(`../../Images/${data.image}`)}
            alt={data.name}
            className="object-contain w-full object-center group-hover:scale-110 transition-all duration-500 "
          />
        </div>
        <div>
          <p className="mt-4 mb-2 text-xl">{data.name}</p>
          <p className="text-light">{data.type}</p>
        </div>
      </>
    );
  };
  return (
    <div className="mt-52" id="work">
      {/* <h2 className="text-gradient text-[120px] sm:text-[200px] md:text-[220px] lg:text-[320px] xl:text-[380px] 2xl:text-[480px] font-black leading-none mx-auto w-max -mb-8 sm:-mb-20 lg:-mb-32 relative">
        WORK
      </h2> */}
      <div className="grid place-items-center w-full relative translate-y-[20%]">
        <img
          src={require("../../Images/work.png")}
          alt="work"
          className="max-w-[1650px] w-full"
        />
      </div>
      <div className="grid sm:grid-cols-2 xl:grid-cols-4 gap-8 relative">
        {data.map((el) => {
          return el.link.includes("https://instagram") ? (
            <a href={el.link} target="_blank" rel="noreferrer" key={el.name}>
              <Project data={el} />
            </a>
          ) : (
            <Link to={el.link} className="text-secondary group" key={el.name}>
              <Project data={el} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default Work;
